<template>
  <v-card>
    <v-card-title>
      <v-icon left>{{icons.mdiInformation}}</v-icon>
      <span class="primary--text-kh">{{$t('caption.accountInfo')}}</span>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="8" md="6">
          Account Name:
          <span class="primary--text">{{currentUser.username}}</span>
        </v-col>
        <v-col cols="12" sm="8" md="6">
          Account Role:
          <span class="primary--text">{{currentUser.roles}}</span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider class="my-2"></v-divider>
    <v-card-title>
      <v-icon left>{{icons.mdiKeyVariant}}</v-icon> 2-Step Authentication</v-card-title>
    <v-card-text class="ma-3">
      <v-row elevation="5">
        <v-col cols="12" sm="12" class="ma-0 pa-0">
          <v-checkbox v-model="user.is2Fa" color="primary" label="Enable Two-Factor Authentication." left @change="enable2Fa" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field label="Hidden for security purpose" outlined dense readonly v-model="user.secret" :append-icon="showSecret ? icons.mdiEye : icons.mdiEyeOff" @click:append="showSecret = !showSecret" :type="showSecret ? 'text' : 'hidden'" />
        </v-col>
        <v-col cols="6" sm="3">
          <v-btn class="primary mx-0" @click="generateCode">
            <v-icon dark left>{{icons.mdiKeyVariant}}</v-icon> Create Secret

          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" sm="3">
          <v-btn class="primary mx-0" @click="saveKey">
            <v-icon dark left>{{icons.mdiContentSave}}</v-icon> Save Secret
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider class="my-2"></v-divider>
    <v-card-title>
      <v-icon left>{{icons.mdiLock}}</v-icon>
      <span class="primary--text-kh">{{$t('caption.changePass')}}</span>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" lazy-validation>
        <v-row>
          <v-col cols="12" sm="8" md="6">
            <v-text-field v-model="data.oldPassword" outlined dense label="Current Password" :rules="fieldRules" type="password"></v-text-field>

            <v-text-field v-model="data.newPassword" outlined dense label="New Password" :rules="fieldRules" type="password"></v-text-field>

            <v-text-field v-model="data.confirmPassword" outlined dense label="Confirm New Password" :rules="fieldRules" type="password"></v-text-field>
            <v-btn color="primary" @click="changePassword">
              <v-icon dark left>{{icons.mdiContentSave}}</v-icon> Update
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiDownload,
  mdiUpdate,
  mdiContentSave,
  mdiKeyVariant,
  mdiInformation,
  mdiLock,
  mdiEye,
  mdiEyeOff
} from '@mdi/js'
import userService from '@/services/service.user'

export default {
  name: 'change-password',
  data() {
    return {
      icons: {
        mdiDownload,
        mdiUpdate,
        mdiContentSave,
        mdiKeyVariant,
        mdiInformation,
        mdiLock,
        mdiEye,
        mdiEyeOff
      },
      fieldRules: [
        v => !!v || 'This field is required',
        v => /^(?!\s*$).+/.test(v) || 'This field cannot be blank'
      ],
      user: {
        secret: '',
        is2Fa: false
      },
      data: {},
      showSecret: false,
      currentUser: JSON.parse(localStorage.getItem('user'))
    }
  },
  mounted() {
    /*
    userService.is2FaEnabled().then(
      response => {
        this.user.is2Fa = response.data;
        
      },
      error => {
        this.user = error.response.data;
        alert(JSON.stringify(this.user))
      }
    ),

    this.getSecretCode()
    */
  },
  methods: {
    changePassword() {
      if (!this.$refs[`form`].validate()) return

      if (this.data.newPassword != this.data.confirmPassword) {
        this.$notify({
          group: 'message',
          title: 'Notification',
          type: 'error',
          text: 'The password confirmation does not match'
        })

        return
      }

      userService.changePassword(this.data).then(
        response => {
          this.resultMessage = response.data
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'success',
            text: JSON.stringify(this.resultMessage)
          })
          this.data = {}
          this.$refs[`form`].reset()
        },
        error => {
          this.resultMessage = error.response.data
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: JSON.stringify(this.resultMessage)
          })
        }
      )
    },
    getSecretCode() {
      userService.getSecretKey().then(response => {
        this.user.secret = response.data
      })
    },
    generateCode() {
      userService.getSecretCode().then(response => {
        this.user.secret = response.data
      })
    },
    enable2Fa(e) {
      this.user.is2Fa = e
      userService.enable2FA(this.user).then(
        response => {
          alert('You have set 2-step authentication to ' + response.data.is2Fa)
        },
        error => {
          alert(JSON.stringify(error.response.data))
        }
      )
    },
    saveKey() {
      userService.saveSecretCode(this.user).then(
        () => {
          this.resultMessage = 'Secret key has been saved'
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'success',
            text: this.resultMessage
          })
        },
        error => {
          alert(JSON.stringify(error.response.data))
        }
      )
    }
  }
}
</script>
